












import { Component, Vue } from 'nuxt-property-decorator'

@Component
export default class HeightTransition extends Vue {
  beforeEnter(el: HTMLElement): void {
    el.style.height = '0'
  }

  enter(el: HTMLElement): void {
    el.style.height = el.scrollHeight + 'px'
  }

  beforeLeave(el: HTMLElement): void {
    el.style.height = el.scrollHeight + 'px'
  }

  leave(el: HTMLElement): void {
    el.style.height = '0'
  }
}
