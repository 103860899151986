









import { Component, Vue, Prop } from 'nuxt-property-decorator'
import ChevronLeftSvg from '@icons/material/svg/chevron-left.svg?inline'

@Component({
  components: {
    ChevronLeftSvg,
  },
})
export default class Back extends Vue {
  @Prop({ type: Function }) method?: Function
  @Prop({ type: String }) label?: string

  goBack(): void {
    if (this.method && typeof this.method === 'function') {
      this.method()
    } else {
      this.$router.back()
    }
  }

  get buttonText() {
    return this.label ? this.label : 'Back'
  }
}
