































import { Component, Vue, Watch, namespace } from 'nuxt-property-decorator'
// @ts-ignore
import SearchIcon from '@icons/material/svg/magnify.svg?inline'
import SearchBar from '@UI/components/search-form/SearchBar.vue'
import SearchTrigger from '@UI/components/search-form/SearchTrigger.vue'
import MostSearched from '@UI/components/search-form/MostSearched.vue'
import Modal from '@UI/components/modals/Modal.vue'
import SearchResults from '@UI/components/search-form/SearchResults.vue'
import { Throttle } from '@Core/decorators'
import { CmsSearch, Event, Series } from '@Core/@types/skyway'
import { searchAll } from '@Events/helpers/filters'

const options = namespace('options')
const archive = namespace('archive')

@Component({
  components: {
    SearchBar,
    MostSearched,
    Modal,
    SearchIcon,
    SearchResults,
    SearchTrigger,
  },
})
export default class SearchForm extends Vue {
  @options.Getter
  public optionComponentByName

  @options.Action
  public getCMSOptionsComponents

  @archive.Action
  public searchCMS

  protected events: Event[] = []
  protected series: Series[] = []

  protected searchTerm: string = ''
  protected loading: boolean = false
  protected results: CmsSearch | null = null

  get mostSearched() {
    const data = this.optionComponentByName('FeaturedSearchQueryCollection')

    return (data && data.queries) || []
  }

  get hasResults() {
    return Boolean(
      this.results && this.results.results && this.results.results?.length > 0
    )
  }

  get latestEvents() {
    return this.events
      ? this.events.slice(0, 3).map((event) => {
          return {
            ...event,
            slug: `/whats-on/${event.alias}`,
          }
        })
      : []
  }

  closeSearchForm(): void {
    this.results = null
    this.searchTerm = ''
    if (this.$refs.searchform) {
      // @ts-ignore
      this.$refs.searchform.close()
    }
  }

  onOpenSearch(open): void {
    this.openSearchForm()
  }

  openSearchForm(): void {
    if (this.$refs.searchform) {
      // @ts-ignore
      if (!this.$refs.searchform.isOpen) {
        // @ts-ignore
        this.$refs.searchform.open()
        this.results = null
        const internal: HTMLInputElement | null = document.querySelector(
          '.search-bar__field input'
        )

        if (internal) {
          internal.focus()
        }
      }
    }
  }

  transformEventResults(events) {
    return {
      results: events.reduce((acc, e) => {
        ;(acc[e.type] = acc[e.type] || []).push(e)
        return acc
      }, []),
    }
  }

  @Throttle(1500)
  async onUpdateSearchTerm() {
    this.results = null

    const response = await this.searchCMS({
      term: this.searchTerm,
      type: 'page, read, watch, listen',
    })

    const eventResults = searchAll(this.events, this.searchTerm)
    const seriesResults = searchAll(this.series, this.searchTerm)

    const results = response
    const eventSearch = {
      results: eventResults
        .filter((e) => !e.private)
        .map((e) => {
          return { ...e, slug: `/whats-on/${e.alias}` }
        }),
      type: 'event',
    }

    const seriesSearch = {
      results: seriesResults
        .filter((s) => !s.private)
        .map((s) => {
          return { ...s, slug: `/series/${s.alias}`, type: 'series' }
        }),
      type: 'series',
    }

    results.results = results.results.concat(eventSearch)
    results.results = results.results.concat(seriesSearch)

    this.results = results
  }

  async getEvents() {
    const data = await this.$events.getEvents('getEventsShort')

    this.events = (data && data.getEvents) || []
  }

  async getSeries() {
    const data = await this.$events.getSeriesShort()

    this.series = (data && data.getSeries) || []
  }

  async initiateSearch() {
    if (!this.loading) {
      this.loading = true

      if (this.events.length < 1) {
        await this.getEvents()
      }

      if (this.series.length < 1) {
        await this.getSeries()
      }

      await this.onUpdateSearchTerm()

      this.openSearchForm()

      this.loading = false
      this.searchTerm = ''
    }
  }

  @Watch('$route')
  watchRouteChange(newVal, oldVal): void {
    if (newVal && newVal !== oldVal) {
      this.closeSearchForm()
    }
  }
}
