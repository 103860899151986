






import { Component, ProvideReactive, Vue, Watch } from 'nuxt-property-decorator'

@Component
export default class Observer extends Vue {
  $refs!: {
    observer: HTMLElement
  }
  protected observer: ResizeObserver | null = null

  @ProvideReactive() contentBoxSize: Object | null = null

  @Watch('contentBoxSize', { immediate: false })
  onContentBoxSizeChange() {
    if (
      matchMedia(
        'all and (orientation:landscape) and (max-device-width: 1024px)'
      ).matches
    ) {
      alert('This site works better with your device in portrait orientation')
    }
  }

  createObserver(): void {
    this.observer = new ResizeObserver(([entry]) => {
      if (entry && entry.contentRect) {
        this.contentBoxSize = {
          width: entry.contentRect.width,
          height: entry.contentRect.height,
        }
      }
    })

    if (this.$refs.observer) {
      this.observer.observe(this.$refs.observer)
    }
  }

  mounted() {
    this.$nextTick(() => {
      if (!this.observer) {
        this.createObserver()
      }
    })
  }

  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect()
    }
  }
}
