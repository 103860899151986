









import { Component, Prop, Vue } from 'nuxt-property-decorator'
import LoadingWheel from '@UI/components/loader/LoadingWheel.vue'

@Component({
  components: {
    LoadingWheel,
  },
})
export default class Loader extends Vue {
  @Prop({
    type: String,
  })
  message?: string

  @Prop({ type: Boolean, default: true }) image?: boolean
  @Prop({ type: Boolean, default: true }) text?: boolean
  @Prop({ type: String, default: '' }) variant?: string

  get classes() {
    let classes = this.image ? ' has-image' : ' no-image'
    classes += this.text ? ' has-text' : ' no-text'
    classes += ` ${this.variant}`
    return classes
  }
}
