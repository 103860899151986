













































import { Component, Prop, mixins } from 'nuxt-property-decorator'
import Field from '@UI/components/forms/fields/Field.vue'

export interface SelectOption {
  label: string
  value: any
}

@Component
export default class SelectField extends mixins<Field>(Field) {
  @Prop({ type: Array, required: true }) options!: SelectOption
  @Prop({ type: String }) descriptiveLabel?: string
}
