





import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class TextTag extends Vue {
  @Prop({ type: Boolean, default: false }) lightMode!: boolean
}
