



























import { Component, Prop, mixins } from 'nuxt-property-decorator'
import ArrowLink from '@UI/components/links/ArrowLink.vue'
import ArrowRightSvg from '~/static/images/icons/arrow.svg?inline'
import ArrowUpRightSvg from '~/static/images/icons/arrow-up-right.svg?inline'
import ArrowDownSvg from '~/static/images/icons/arrow-down.svg?inline'

@Component({
  components: {
    ArrowRightSvg,
    ArrowUpRightSvg,
    ArrowDownSvg,
  },
})
export default class ButtonLink extends mixins<ArrowLink>(ArrowLink) {
  @Prop({ type: String, default: 'primary' }) linkStyle!: string
  @Prop({ type: Boolean, default: true }) hasIcon!: boolean
  @Prop({ type: Boolean, default: false }) disabled!: boolean

  get arrowIcon(): string {
    if (this.external) {
      return 'ArrowUpRightSvg'
    } else if (this.isDownload) {
      return 'ArrowDownSvg'
    } else {
      return 'ArrowRightSvg'
    }
  }
}
