
















import { Component, Vue } from 'nuxt-property-decorator'

@Component
export default class SearchTrigger extends Vue {
  search(): void {
    this.$emit('open-search')
  }
}
