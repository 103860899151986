
import { Component, Prop, Vue } from 'vue-property-decorator'
import { ValidationProvider, extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import { messages } from 'vee-validate/dist/locale/en.json'

/**
 * @todo - the global vee-validate configuration should be moved to a plugin
 */
Object.keys(rules).forEach((rule) => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule], // assign message
  })
})

extend('required', {
  message: 'This field is required',
})

extend('confirmed', {
  message: 'The password confirmation does not match',
})

extend('regex', {
  message: 'Please use alphabetic characters only',
})

extend('password', (value) => {
  if (value.match(/[0-9]/g) !== null && value.match(/[a-zA-Z]/g) !== null) {
    return true
  }

  return 'The {_field_} must contain at least one number and one letter'
})

extend('email', {
  message: 'Please enter a valid email',
})

@Component({
  components: {
    ValidationProvider,
  },
})
export default class Field extends Vue {
  @Prop({ type: String, required: false }) label!: string
  @Prop({ type: String }) value!: string
  @Prop({ type: String }) id!: string
  @Prop({ type: String }) vid?: string
  @Prop({ type: String }) placeholder!: string
  @Prop({ type: Boolean }) disabled!: string
  @Prop({ type: String }) rules?: string
  @Prop({ type: String }) name?: string
  @Prop({ type: String, default: 'eager' }) mode?: string
  @Prop({ type: String, default: 'text' }) fieldType!: string
  @Prop({ type: String, default: '' }) wrapperClass?: string
  @Prop({ default: false }) pattern?: string | boolean
  @Prop({ default: null }) maxlength?: number
  @Prop({ type: Boolean, default: false }) hideLabel!: boolean

  get required() {
    return this.compiledRules.includes('required')
  }

  get compiledRules(): string {
    const rules = this.rules || ''
    const output = [rules]
    if (
      (rules === '' || rules.includes('required')) &&
      this.$attrs.required === 'true'
    ) {
      output.push(`required`)
    }
    return output.join('|')
  }

  get localValue() {
    return this.value
  }

  set localValue(localValue) {
    this.$emit('input', localValue)
  }

  onInput(event) {
    this.$emit('input', event.target.value)
  }
}
