<template>
  <client-only>
    <div
      ref="parent"
      :class="[
        {
          'is-focused': isFocus,
          'has-value': value,
          'has-hint': hint,
          'has-error': error,
          'is-disabled': disabled,
          'is-dark': dark,
          'no-flags': noFlags,
          'has-list-open': hasListOpen,
          'is-valid': valid,
        },
        size,
      ]"
      class="country-selector"
      @blur.capture="handleBlur"
      @mouseenter="updateHoverState(true)"
      @mouseleave="updateHoverState(false)"
    >
      <div
        v-if="value && !noFlags"
        class="country-selector__country-flag"
        @click.stop="toggleList"
      >
        <div :class="`iti-flag-small iti-flag ${value.toLowerCase()}`" />
      </div>
      <input
        :id="id"
        ref="CountrySelector"
        :value="callingCode"
        :placeholder="label"
        :disabled="disabled"
        class="country-selector__input"
        readonly
        :style="[inputBoxShadowStyle, inputBgColor]"
        @focus="isFocus = true"
        @keydown="keyboardNav"
        @click.stop="toggleList"
      />
      <div class="country-selector__toggle" @click.stop="toggleList">
        <slot name="arrow">
          <ArrowDownSVG />
          <!-- <svg
          mlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          class="country-selector__toggle__arrow"
        >
          <path
            class="arrow"
            d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"
          />
          <path fill="none" d="M0 0h24v24H0V0z" />
        </svg> -->
        </slot>
      </div>
      <label
        ref="label"
        :style="[labelColorStyle]"
        class="country-selector__label"
        @click.stop="toggleList"
      >
        {{ hint || label }}
      </label>

      <div
        v-show="hasListOpen"
        ref="countriesList"
        class="country-selector__list"
        :class="{ 'has-calling-code': showCodeOnList }"
        :style="[radiusStyle, listHeight, inputBgColor]"
      >
        <button
          v-for="item in countriesSorted"
          :key="`item-${item.iso2}`"
          :class="[
            { selected: value === item.iso2 },
            {
              'keyboard-selected':
                value !== item.iso2 && tmpValue === item.iso2,
            },
          ]"
          class="flex align-center country-selector__list__item"
          :style="[
            itemHeight,
            value === item.iso2 ? bgItemSelectedStyle : null,
          ]"
          tabindex="-1"
          type="button"
          @click.stop="updateValue(item.iso2)"
        >
          <div
            v-if="!noFlags"
            class="country-selector__list__item__flag-container"
          >
            <div
              :class="`iti-flag-small iti-flag ${item.iso2.toLowerCase()}`"
            />
          </div>
          <span
            v-if="showCodeOnList"
            class="country-selector__list__item__calling-code flex-fixed"
            >+{{ item.dialCode }}</span
          >
          <div class="dots-text">
            {{ item.name }}
          </div>
        </button>
      </div>
    </div>
  </client-only>
</template>

<script>
import { getCountryCallingCode } from 'libphonenumber-js'
import StylesHandler from '@UI/components/forms/fields/vue-phone-number-input/mixins/StylesHandler'
import ArrowDownSVG from '~/static/images/svgs/arrow-down.svg?inline'

export default {
  name: 'CountrySelector',
  mixins: [StylesHandler],
  components: {
    ArrowDownSVG,
  },
  props: {
    id: { type: String, default: 'CountrySelector' },
    value: { type: [String, Object] },
    label: { type: String, default: 'Choose country' },
    hint: { type: String, default: String },
    size: { type: String, default: String },
    error: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    valid: { type: Boolean, default: false },
    dark: { type: Boolean, default: false },
    items: { type: Array, default: () => [], required: true },
    preferredCountries: { type: Array, default: () => [] },
    onlyCountries: { type: Array, default: () => [] },
    ignoredCountries: { type: Array, default: () => [] },
    noFlags: { type: Boolean, default: false },
    countriesHeight: { type: Number, default: 42 },
    showCodeOnList: { type: Boolean, default: false },
  },
  data() {
    return {
      isFocus: false,
      hasListOpen: false,
      selectedIndex: null,
      tmpValue: this.value,
      query: '',
      indexItemToShow: 0,
      isHover: false,
    }
  },
  computed: {
    itemHeight() {
      return {
        height: `${this.countriesHeight}px`,
      }
    },
    listHeight() {
      return {
        height: `${(this.countriesHeight + 1) * 7}px`,
        maxHeight: `${(this.countriesHeight + 1) * 7}px`,
      }
    },
    countriesList() {
      return this.items.filter(
        (item) => !this.ignoredCountries.includes(item.iso2)
      )
    },
    countriesFiltered() {
      const countries = this.onlyCountries || this.preferredCountries
      return countries.map((country) =>
        this.countriesList.find((item) => item.iso2.includes(country))
      )
    },
    otherCountries() {
      return this.countriesList.filter(
        (item) => !this.preferredCountries.includes(item.iso2)
      )
    },
    countriesSorted() {
      return this.preferredCountries
        ? [...this.countriesFiltered, ...this.otherCountries]
        : this.onlyCountries
        ? this.countriesFiltered
        : this.countriesList
    },
    selectedValueIndex() {
      return this.value
        ? this.countriesSorted.findIndex((c) => c.iso2 === this.value)
        : null
    },
    tmpValueIndex() {
      return this.countriesSorted.findIndex((c) => c.iso2 === this.tmpValue)
    },
    callingCode() {
      return this.value ? `+${getCountryCallingCode(this.value)}` : null
    },
  },
  methods: {
    updateHoverState(value) {
      this.isHover = value
    },
    handleBlur(e) {
      if (this.$el.contains(e.relatedTarget)) return
      this.isFocus = false
      this.closeList()
    },
    toggleList() {
      this.$refs.countriesList.offsetParent ? this.closeList() : this.openList()
    },
    openList() {
      if (!this.disabled) {
        this.$refs.CountrySelector.focus()
        this.$emit('open')
        this.isFocus = true
        this.hasListOpen = true
        if (this.value) this.scrollToSelectedOnFocus(this.selectedValueIndex)
      }
    },
    closeList() {
      this.$emit('close')
      this.hasListOpen = false
    },
    async updateValue(val) {
      this.tmpValue = val
      this.$emit('input', val || null)
      await this.$nextTick()
      this.closeList()
    },
    scrollToSelectedOnFocus(arrayIndex) {
      this.$nextTick(() => {
        // this.indexItemToShow = arrayIndex - 3
        this.$refs.countriesList.scrollTop =
          arrayIndex * (this.countriesHeight + 1) -
          (this.countriesHeight + 1) * 3
      })
    },
    keyboardNav(e) {
      const code = e.keyCode
      if (code === 40 || code === 38) {
        // arrow up down
        if (e.view && e.view.event) {
          // TODO : It's not compatible with FireFox
          e.view.event.preventDefault()
        }
        if (!this.hasListOpen) this.openList()
        let index =
          code === 40 ? this.tmpValueIndex + 1 : this.tmpValueIndex - 1
        if (index === -1 || index >= this.countriesSorted.length) {
          index = index === -1 ? this.countriesSorted.length - 1 : 0
        }
        this.tmpValue = this.countriesSorted[index].iso2
        this.scrollToSelectedOnFocus(index)
      } else if (code === 13) {
        // enter
        this.hasListOpen ? this.updateValue(this.tmpValue) : this.openList()
      } else if (code === 27) {
        // escape
        this.closeList()
      } else {
        // typing a country's name
        this.searching(e)
      }
    },
    searching(e) {
      const code = e.keyCode
      clearTimeout(this.queryTimer)
      this.queryTimer = setTimeout(() => {
        this.query = ''
      }, 1000)
      const q = String.fromCharCode(code)
      if (code === 8 && this.query !== '') {
        this.query = this.query.substring(0, this.query.length - 1)
      } else if (/[a-zA-Z-e ]/.test(q)) {
        if (!this.hasListOpen) this.openList()
        this.query += e.key
        const countries = this.preferredCountries
          ? this.countriesSorted.slice(this.preferredCountries.length)
          : this.countriesSorted
        const resultIndex = countries.findIndex((c) => {
          this.tmpValue = c.iso2
          return c.name.toLowerCase().startsWith(this.query)
        })
        if (resultIndex !== -1) {
          this.scrollToSelectedOnFocus(
            resultIndex +
              (this.preferredCountries ? this.preferredCountries.length : 0)
          )
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import './assets/iti-flags/flags.css';
.country-selector {
  position: relative;
  z-index: 0;
  user-select: none;
  margin-top: 8px;

  &:hover {
    z-index: 1;
  }

  &__label {
    position: absolute;
    top: 3px;
    cursor: pointer;
    left: 11px;
    transform: translateY(25%);
    opacity: 0;
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    font-size: 11px;
  }

  &__input {
    cursor: pointer;
    position: relative;
    width: 100%;
    font-weight: 400;
    font-size: 13px;
    z-index: 0;
    padding: padding(1.5) padding(2);
    line-height: 1.1875rem;
  }

  &__toggle {
    position: absolute;
    right: 5px;
    top: rem(19);
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    text-align: center;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    height: 24px;
  }

  &__country-flag {
    margin: auto 0;
    position: absolute;
    top: 23px;
    left: 11px;
    z-index: 1;
    cursor: pointer;

    img {
      position: absolute;
    }
  }

  &__list {
    max-width: 100%;
    top: 100%;
    width: 100%;
    min-width: 230px;
    position: absolute;
    overflow: hidden;
    z-index: 9;
    list-style: none;
    overflow-y: auto;
    overflow-x: hidden;
    padding: rem(16) 0 0;
    margin: 0;
    background-color: $white;
    border: $inputBorder;

    &.has-calling-code {
      min-width: 270px;
    }

    &__item {
      padding: 0 10px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-size: 12px;
      cursor: pointer;
      background-color: transparent;
      width: 100%;
      border: 0;
      outline: none;
      align-items: center;
      margin: 0;

      &__flag-container {
        margin-right: 10px;
      }

      &__calling-code {
        width: 45px;
      }

      &:hover {
        background-color: lighten($violet, 10%);
        cursor: pointer;
        color: $white;
      }

      &.keyboard-selected {
        background-color: $violet;
      }

      &.selected {
        color: #fff;
        font-weight: 700;

        .country-selector__list__item__calling-code {
          color: #fff;
        }
      }
    }
  }
}

@import 'assets/iti-flags/flags-list';
</style>
