
































import { Component, Prop } from 'nuxt-property-decorator'
import UIButton from '@UI/components/buttons/UIButton.vue'

@Component
export default class UIButtonLink extends UIButton {
  @Prop({ required: true }) to!: string | any
  @Prop({ type: String }) extraText?: string
}
