











































import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator'
import Multiselect from 'vue-multiselect'
// @ts-ignore
import ArrowDownSVG from '~/static/images/svgs/arrow-down.svg?inline'

@Component({
  components: {
    Multiselect,
    ArrowDownSVG,
  },
})
/**
 * This is a wrapper around the vue-multiselect component
 * @version 1.0.0
 * @author [After Digital](https://afterdigital.co.uk)
 * @since Version 1.0.0
 */
export default class AutoSuggestField extends Vue {
  @Prop({ type: Array, default: () => [] }) options!: Array<any>
  @Prop({ type: String, required: true }) label!: string
  @Prop({ type: String }) fieldLabel?: string
  @Prop({ type: String }) id?: string
  @Prop() preset?: any
  @Prop(String) trackBy?: string
  @Prop(String) placeholder?: string
  @Prop(String) rules?: string

  public value: any = null

  public updating: boolean = false

  @Watch('preset')
  onPresetUpdated(newVal, oldVal) {
    if (newVal && newVal !== oldVal) {
      this.value = this.preset
    }
  }

  optionLabel(option) {
    return option[this.label]
  }

  onSelect(value) {
    this.$emit('input', value)
    this.$emit('auto-suggest:clicked', value)
  }

  mounted() {
    if (this.preset) {
      this.value = this.preset
    }
  }
}
