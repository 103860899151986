var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ui-radio-group"},[(_vm.label)?_c('label',{staticClass:"ui-radio-group__label text text--accent"},[_vm._t("label",function(){return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.label)}})]})],2):_vm._e(),_vm._v(" "),_c('ValidationProvider',{staticClass:"ui-radio-group__wrapper",attrs:{"tag":"div","rules":_vm.isRequired,"mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_vm._l((_vm.options),function(option,index){return _c('RadioButton',{key:index,staticClass:"ui-radio-group__button ui-radio ui-radio--boxed",class:{
        'ui-radio-group__button-checked': _vm.selection === option.value,
        'radio--boxed': _vm.boxed,
        'ui-radio-group__button--boxed-checked':
          _vm.boxed && _vm.selection === option.value,
      },attrs:{"id":(_vm.name + "_" + index),"option":option,"name":_vm.name,"label":option.label,"description":option.description,"disabled":option.disabled && option.disabled === true,"is-disabled":_vm.isDisabled},model:{value:(_vm.selection),callback:function ($$v) {_vm.selection=$$v},expression:"selection"}})}),_vm._v(" "),(errors[0])?_c('span',{staticClass:"form-errors"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }