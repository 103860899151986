var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('client-only',[_c('div',{ref:"parent",staticClass:"country-selector",class:[
      {
        'is-focused': _vm.isFocus,
        'has-value': _vm.value,
        'has-hint': _vm.hint,
        'has-error': _vm.error,
        'is-disabled': _vm.disabled,
        'is-dark': _vm.dark,
        'no-flags': _vm.noFlags,
        'has-list-open': _vm.hasListOpen,
        'is-valid': _vm.valid,
      },
      _vm.size ],on:{"!blur":function($event){return _vm.handleBlur.apply(null, arguments)},"mouseenter":function($event){return _vm.updateHoverState(true)},"mouseleave":function($event){return _vm.updateHoverState(false)}}},[(_vm.value && !_vm.noFlags)?_c('div',{staticClass:"country-selector__country-flag",on:{"click":function($event){$event.stopPropagation();return _vm.toggleList.apply(null, arguments)}}},[_c('div',{class:("iti-flag-small iti-flag " + (_vm.value.toLowerCase()))})]):_vm._e(),_vm._v(" "),_c('input',{ref:"CountrySelector",staticClass:"country-selector__input",style:([_vm.inputBoxShadowStyle, _vm.inputBgColor]),attrs:{"id":_vm.id,"placeholder":_vm.label,"disabled":_vm.disabled,"readonly":""},domProps:{"value":_vm.callingCode},on:{"focus":function($event){_vm.isFocus = true},"keydown":_vm.keyboardNav,"click":function($event){$event.stopPropagation();return _vm.toggleList.apply(null, arguments)}}}),_vm._v(" "),_c('div',{staticClass:"country-selector__toggle",on:{"click":function($event){$event.stopPropagation();return _vm.toggleList.apply(null, arguments)}}},[_vm._t("arrow",function(){return [_c('ArrowDownSVG')]})],2),_vm._v(" "),_c('label',{ref:"label",staticClass:"country-selector__label",style:([_vm.labelColorStyle]),on:{"click":function($event){$event.stopPropagation();return _vm.toggleList.apply(null, arguments)}}},[_vm._v("\n      "+_vm._s(_vm.hint || _vm.label)+"\n    ")]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasListOpen),expression:"hasListOpen"}],ref:"countriesList",staticClass:"country-selector__list",class:{ 'has-calling-code': _vm.showCodeOnList },style:([_vm.radiusStyle, _vm.listHeight, _vm.inputBgColor])},_vm._l((_vm.countriesSorted),function(item){return _c('button',{key:("item-" + (item.iso2)),staticClass:"flex align-center country-selector__list__item",class:[
          { selected: _vm.value === item.iso2 },
          {
            'keyboard-selected':
              _vm.value !== item.iso2 && _vm.tmpValue === item.iso2,
          } ],style:([
          _vm.itemHeight,
          _vm.value === item.iso2 ? _vm.bgItemSelectedStyle : null ]),attrs:{"tabindex":"-1","type":"button"},on:{"click":function($event){$event.stopPropagation();return _vm.updateValue(item.iso2)}}},[(!_vm.noFlags)?_c('div',{staticClass:"country-selector__list__item__flag-container"},[_c('div',{class:("iti-flag-small iti-flag " + (item.iso2.toLowerCase()))})]):_vm._e(),_vm._v(" "),(_vm.showCodeOnList)?_c('span',{staticClass:"country-selector__list__item__calling-code flex-fixed"},[_vm._v("+"+_vm._s(item.dialCode))]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"dots-text"},[_vm._v("\n          "+_vm._s(item.name)+"\n        ")])])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }