



import { Component, Prop, Vue } from 'nuxt-property-decorator'

export interface IPaginatable {
  perPage: number
  paginatedDataKey: string
}

/**
 * Apply the paginatable mixin to provide helper functions to components
 * that use a load more
 */
@Component
export default class PaginatesFromServer extends Vue {
  @Prop({ type: Number, default: 9 }) perPage!: number
  @Prop() total!: number
  @Prop({ type: Number, required: true }) currentPage!: number

  public paginated: any[] = []

  get paginatedDataKey() {
    return Array.isArray(this['items'])
      ? this['items']
      : Object.values(this['items'])
  }

  /**
   * Computed property returns array of paginated data
   *
   * @return Array<any>
   */
  get paginatedData(): Array<any> {
    return this.paginatedDataKey && this.paginatedDataKey.length
      ? this.paginatedDataKey.slice(this.start - 1, this.stop)
      : []
  }

  get totalPages() {
    return this.paginatedDataKey && this.dataLength > this.perPage
      ? Math.ceil(this.dataLength / this.perPage)
      : 1
  }

  get start() {
    return 1
  }

  get stop() {
    const page = this.currentPage !== undefined ? this.currentPage : 1
    return page * this.perPage
  }

  get dataLength() {
    return this.total
      ? this.total
      : this.paginatedDataKey !== undefined
      ? this.paginatedDataKey.length
      : 0
  }

  paginate() {
    const paginated: Array<any> = []

    if (this.paginatedDataKey.length > 0) {
      this.paginatedDataKey
        .slice(this.start - 1, this.stop)
        .forEach((item, index) => {
          paginated[index] = this.paginatedDataKey[index]
        })
    }

    this.paginated = paginated
  }

  get showLoadMore() {
    return this.currentPage < this.totalPages
  }

  onLoadMore() {
    if (this.currentPage < this.totalPages) {
      this.$emit('load-next', this.currentPage)

      this.paginate()
    }
  }
}
