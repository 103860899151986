


































import { Component, Prop, VModel, Vue } from 'nuxt-property-decorator'
// @ts-ignore:next-line
import RadioboxMarked from '@icons/material/svg/radiobox-marked.svg?inline'
// @ts-ignore:next-line
import RadioboxBlank from '@icons/material/svg/radiobox-blank.svg?inline'

@Component({
  components: {
    RadioboxMarked,
    RadioboxBlank,
  },
})
export default class RadioButton extends Vue {
  @Prop({ type: Object, required: true }) option!: {
    label: string
    value: string
  }
  @Prop({ type: String }) label?: string
  @Prop({ type: String }) description?: string
  @Prop({ type: Boolean, default: false }) disabled?: boolean
  @Prop({ type: Boolean, default: false }) boxed?: boolean

  @VModel() selection!: any

  get showCheckedIcon(): boolean {
    return this.selection === this.option.value
  }

  makeSelection(): void {
    this.selection = this.option.value
  }
}
