





import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class Observer extends Vue {
  @Prop({ type: String, default: '50px' }) margin!: string
  protected observer: IntersectionObserver | null = null

  createObserver(): void {
    this.observer = new IntersectionObserver(
      ([entry]) => {
        if (entry && entry.isIntersecting) {
          this.$emit('intersect')
        }
      },
      { rootMargin: this.margin }
    )

    const el = this.$refs.observer

    if (el) {
      this.observer.observe(el)
    }
  }

  async mounted() {
    await this.$nextTick()
    this.createObserver()
  }

  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect()
    }
  }
}
