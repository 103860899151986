






























import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'
import ArrowRightSvg from '~/static/images/icons/arrow.svg?inline'

@Component({
  components: {
    ArrowRightSvg,
  },
})
export default class UIButton extends Vue {
  @Prop({ type: Boolean, default: true }) hasIcon!: boolean
  @Prop({ type: String, default: 'button' }) ariaLabel!: string
  @Prop({ type: Boolean, default: false }) disabled!: boolean
  @Prop({ type: String, default: 'button' }) type!: string

  @Emit()
  click($event) {
    return $event
  }
}
