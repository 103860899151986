























































import { Component, Prop, VModel } from 'vue-property-decorator'
import Field from '@UI/components/forms/fields/Field.vue'

@Component
export default class TextField extends Field {
  @Prop() classes?: string
  @Prop() descriptiveLabel?: string
  @Prop() helperText?: string
  @Prop({ type: Number }) min?: number
  @Prop({ type: Number }) max?: number
  @Prop({ type: Number }) step?: number
  @VModel() fieldValue!: string | number

  focus() {
    // eslint-disable-next-line no-unused-expressions
    this.$refs?.input?.focus()
  }

  errorsClass(errors) {
    return errors ? 'field__input--error' : ''
  }
}
