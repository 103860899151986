









import { Component, Prop, mixins, Watch } from 'nuxt-property-decorator'
import PaginatesFromServer from '@UI/components/lists/helpers/PaginatesFromServer.vue'
import LoadMore from '@UI/components/buttons/LoadMore.vue'
import _throttle from 'lodash/throttle'

@Component({
  components: {
    LoadMore,
  },
})
export default class InfiniteList extends mixins<PaginatesFromServer>(
  PaginatesFromServer
) {
  @Prop({ type: [Array, Object] }) items!: any[]
  @Prop({ type: String, default: '0px 0px 50px 0px' }) rootMargin!: string
  @Prop({ type: Number, default: 0.25 }) threshold!: number
  @Prop({ type: Boolean, default: true }) useObserver!: boolean

  @Watch('perPage')
  onChangePerPage(newVal, oldVal): void {
    if (newVal !== oldVal) {
      this.$emit('resized')
    }
  }

  @Watch('showLoadMore')
  onLoadMoreChange(newVal, oldVal) {
    if (!this.observer && this.useObserver) {
      this.$nextTick(() => {
        this.createObserver()
      })
    }
  }

  public root: any
  public increment: number = 0
  public observer: IntersectionObserver | null = null

  public throttledIntersectionHandler = _throttle(this.handleIntersection, 400)

  createObserver(): void {
    this.observer = new IntersectionObserver(
      (entries) => {
        let entry = entries[0]
        if (entries.length > 1) {
          const intersectingEntry = entries.find((e) => e.isIntersecting)

          if (intersectingEntry) {
            entry = intersectingEntry
          }
        }
        if (entry.isIntersecting && entry.intersectionRatio >= this.threshold) {
          this.throttledIntersectionHandler()
        }
      },
      {
        root: this.root,
        rootMargin: this.rootMargin,
        threshold: this.threshold,
      }
    )

    // @ts-ignore
    const observer: Element = this.$refs.observer

    if (observer) {
      this.observer.observe(observer)
    }
  }

  handleIntersection() {
    this.onLoadMore()
  }

  mounted(): void {
    if (this.useObserver) {
      this.$nextTick(() => {
        this.createObserver()
      })
    }
  }

  beforeDestroy(): void {
    if (this.observer) {
      this.observer.disconnect()
    }
  }
}
