















































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import ResultsCard from '@UI/components/search-form/ResultsCard.vue'
import Loader from '@UI/components/loader/Loader.vue'
import InfiniteList from '@UI/components/lists/InfiniteList.vue'
import {
  CmsSearch,
  CmsPostSummary,
  CmsSearchResultType,
} from '@Core/@types/skyway'

@Component({
  components: {
    ResultsCard,
    Loader,
    InfiniteList,
  },
})
export default class SearchResults extends Vue {
  @Prop({
    type: Object,
    default: () => {
      return { results: [] }
    },
  })
  resultSet!: CmsSearch
  @Prop({ type: Boolean, default: false }) isLoading!: boolean

  protected currentFilter: string = 'All'

  protected page: number = 1

  get types(): string[] {
    return this.resultSet && this.resultSet.results
      ? this.resultSet.results
          .reduce(
            (acc, item) => {
              if (item && item.type && !acc.includes(item.type)) {
                acc.push(item.type)
              }
              return acc
            },
            ['All']
          )
          .sort((a, b) => (a === 'event' ? -1 : 0))
      : []
  }

  onLoadMore() {
    this.page++
  }

  changeActiveFilter(filter) {
    this.currentFilter = filter
  }

  getResultsByType(type: string): CmsPostSummary[] {
    if (type === 'All') {
      const all: any = []
      this.types.forEach((type) => {
        const set: CmsSearchResultType = (this.resultSet.results &&
          this.resultSet.results.find((r) => r && r.type === type)) || {
          results: [],
        }
        if (set && set.results) {
          all.push(...set.results)
        } else {
          all.push({ results: [] })
        }
      })

      return all
    }
    const set: CmsSearchResultType = (this.resultSet
      .results as CmsSearchResultType)
      ? this.resultSet.results?.find((r) => r && r.type === type) || {
          results: [],
        }
      : {
          results: [],
        }

    return (set && (set.results as CmsPostSummary[])) || []
  }
}
