











import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { CmsImage, CmsImageSize, Maybe } from '@Core/@types/skyway'

const fallbackImage: CmsImageSize[] = [
  {
    name: 'medium',
    url: '/images/fallback.jpg',
  },
  {
    name: 'medium-retina',
    url: '/images/fallback.jpg',
  },
  {
    name: 'portrait',
    url: '/images/fallback.jpg',
  },
  {
    name: 'large',
    url: '/images/fallback.jpg',
  },
  {
    name: 'large-retina',
    url: '/images/fallback.jpg',
  },
  {
    name: 'widescreen',
    url: '/images/fallback.jpg',
  },
  {
    name: 'widescreen-retina',
    url: '/images/fallback.jpg',
  },
]

export type ImageSizeNames =
  | 'hero'
  | '2048x2048'
  | '1536x1536'
  | 'large'
  | 'medium_large'
  | 'medium'
  | 'thumbnail'
  | 'original'

/**
 * Use this component to handle parsing and displaying image data from the CMS
 * @Prop { CmsImage } - data
 * @Prop { ImageSizeNames } - sizeToDisplay @default 'large'
 * @Prop { boolean } - showDimensions @default false
 */
@Component
export default class UICmsImage extends Vue {
  @Prop({ type: [Object, null] }) data?: CmsImage | null
  @Prop({ type: String, default: 'large' }) sizeToDisplay!: ImageSizeNames
  @Prop({ type: Boolean, default: false }) showDimensions!: boolean
  @Prop({ type: String, default: '' }) imageRatio!: string

  get sizes(): Maybe<Maybe<CmsImageSize>[]> {
    if (this.data && this.data.sizes && this.data.sizes.length > 0) {
      return this.data.sizes
    }

    return fallbackImage
  }

  get image() {
    return this.parseSizes(this.sizeToDisplay)
  }

  get srcSet(): string {
    return `${this.retinaImageSrc ? this.retinaImageSrc + ' 2x,' : ''}${
      this.imageSrc ? this.imageSrc + ' 1x' : ''
    }`
  }

  get retinaImage(): Maybe<CmsImageSize> | void {
    if (this.sizes) {
      const hasRetinaSize = this.sizes.find(
        ({ name }) => name === `${this.sizeToDisplay}-retina`
      )
      const imageName = hasRetinaSize
        ? `${this.sizeToDisplay}-retina`
        : this.sizeToDisplay

      return this.parseSizes(imageName)
    }
  }

  get imageSrc(): Maybe<string> {
    if (this.image && this.image.url && this.image.url !== 'false') {
      return this.parseAbsRelativePath(this.image.url)
    }

    return null
  }

  get retinaImageSrc() {
    if (this.retinaImage && this.retinaImage.url) {
      return this.parseAbsRelativePath(this.retinaImage.url)
    }

    return null
  }

  get height() {
    return this.image ? this.image.height : null
  }

  get width() {
    return this.image ? this.image.width : null
  }

  parseAbsRelativePath(url: string) {
    return url.includes('http') || url.includes('fallback')
      ? encodeURI(url)
      : `${this.$config.get('ASSET_PATH')}/${url}`
  }

  parseSizes(size: string) {
    let output: Maybe<CmsImageSize> | undefined

    if (this.sizes) {
      const hasSizes = this.sizes.find(({ name }) => name === size)

      output =
        typeof hasSizes !== 'undefined'
          ? hasSizes
          : (output = this.sizes.find(({ name }) => name === 'large'))
    }
    return output
  }
}
