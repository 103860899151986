import { render, staticRenderFns } from "./TextTag.vue?vue&type=template&id=5dfc0b27&"
import script from "./TextTag.vue?vue&type=script&lang=ts&"
export * from "./TextTag.vue?vue&type=script&lang=ts&"
import style0 from "./TextTag.vue?vue&type=style&index=0&id=5dfc0b27&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports