




































import { Component, Prop, VModel, Vue } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'
import RadioButton from '@UI/components/forms/fields/RadioButton.vue'

@Component({
  components: {
    ValidationProvider,
    RadioButton,
  },
})
export default class RadioGroup extends Vue {
  @Prop({ type: Array }) options!: Array<any>
  @Prop({ type: String, default: () => '' }) label?: string
  @Prop({ type: String, required: true }) name!: string
  @Prop({ type: Boolean }) required!: Boolean
  @Prop({ type: Boolean }) isDisabled!: Boolean
  @Prop({ type: Boolean, default: () => false }) boxed?: Boolean

  @VModel() selection?: number | null

  get isRequired() {
    return this.required ? { required: { allowFalse: false } } : ''
  }

  selectedClass(index): string {
    return this.selection !== null && index === this.selection
      ? 'ui-radio__button--selected'
      : ''
  }
}
