


















import { Component, Vue, Prop } from 'nuxt-property-decorator'
// @ts-ignore:next-line
import FacebookIcon from '~/static/images/svgs/social-icons/facebook.svg?inline'
// @ts-ignore:next-line
import InstagramIcon from '~/static/images/svgs/social-icons/instagram.svg?inline'
// @ts-ignore:next-line
import TiktokIcon from '~/static/images/svgs/social-icons/tiktok.svg?inline'
// @ts-ignore:next-line
import LinkedinIcon from '~/static/images/svgs/social-icons/linkedin.svg?inline'
// @ts-ignore:next-line
import TwitterIcon from '~/static/images/svgs/social-icons/twitter.svg?inline'
// @ts-ignore:next-line
import YoutubeIcon from '~/static/images/svgs/social-icons/youtube.svg?inline'
@Component({
  components: {
    FacebookIcon,
    InstagramIcon,
    TiktokIcon,
    LinkedinIcon,
    TwitterIcon,
    YoutubeIcon,
  },
})
export default class SocialLinks extends Vue {
  @Prop({ type: Object, required: true }) data!: {
    links: { url: string; type: string }[]
  }

  lowercaseName(linkName: string) {
    const lowerName: string = linkName.replace(/\s+/g, '').toLowerCase()
    return lowerName
  }
  capitalisedCaseName(linkName: string) {
    const noSpaceName: string = linkName.replace(/\s+/g, '')
    const capitalName: string =
      noSpaceName.charAt(0).toUpperCase() + noSpaceName.slice(1)
    return capitalName
  }
}
