














import { Component, Vue, Prop, Emit } from 'nuxt-property-decorator'

@Component
export default class LoadMore extends Vue {
  @Prop({ type: Boolean, default: false }) disabled!: boolean
  @Prop({ type: Boolean, default: false }) loading!: boolean

  @Emit('click')
  handleClick(): string {
    return 'click'
  }
}
