



















import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { CmsLink } from '@Core/@types/skyway'
import ArrowSvg from '~/static/images/icons/arrow.svg?inline'

@Component({ components: { ArrowSvg } })
export default class ArrowLink extends Vue {
  @Prop({ type: Object, required: true }) button!: CmsLink & {
    hash?: string
    text?: string
  }
  @Prop({ type: String, default: 'NuxtLink' }) type?: string
  @Prop({
    type: Object,
    default: () => {
      return {
        truncate: false,
        length: 0,
      }
    },
  })
  truncateOptions!: { truncate: boolean; length: number }

  get nuxtTo() {
    if (this.button.hash) {
      return { path: this.button.url, hash: this.button.hash }
    }

    return this.button.url
  }

  truncate(str: string) {
    if (!str) {
      return str
    }
    return this.truncateOptions.truncate &&
      str.length >= this.truncateOptions.length
      ? str.substring(0, this.truncateOptions.length) + '...'
      : str
  }

  get external() {
    const url = this.button.href || this.button.slug || this.button.url
    return !!(
      url &&
      (url.includes('http') || url.includes('https')) &&
      !url.includes('roundhouse.com')
    )
  }

  get isDownload() {
    const downloadTypes = ['pdf', 'doc', 'docx']
    const url = this.button.href || this.button.slug || this.button.url
    const name = url.substr(url.lastIndexOf('.') + 1, url.length)
    return Boolean(downloadTypes.includes(name))
  }

  get linkType(): string {
    const url = this.button.slug || this.button.url
    return this.button.href ||
      (url && url.includes('http')) ||
      (url && url.includes('mailto'))
      ? 'a'
      : 'NuxtLink'
  }
}
