



























































































































import { Component, Prop, mixins, Watch, VModel } from 'nuxt-property-decorator'
import Field from '@UI/components/forms/fields/Field.vue'
import EyeIcon from '~/static/images/icons/eye.svg?inline'
import CheckIcon from '~/static/images/svgs/check.svg?inline'
import BulletIcon from '~/static/images/svgs/bullet.svg?inline'
import InfoIcon from '~/static/images/svgs/info.svg?inline'

@Component({ components: { EyeIcon, CheckIcon, BulletIcon, InfoIcon } })
export default class PasswordFieldReveal extends mixins(Field) {
  @Prop({ type: String }) errors?: string
  @Prop({ type: String }) name?: string
  @Prop({ type: Boolean, default: false }) hasCheckbox?: boolean
  @Prop({ type: String, default: '' }) hint!: string
  @Prop({ type: Boolean }) showChecker?: boolean
  @Prop({ type: Boolean }) showErrors?: boolean

  @VModel({ type: String }) password!: string

  public is_showing: boolean = false

  toggle(): void {
    this.is_showing = !this.is_showing
  }
  protected validSpecialChars = /.*[@#$%^*+!()"£{}[\];'?/\\=_|\-~¬`]/g
  protected hasNumber = /\d/
  protected hasUppercase = /.*[A-Z]/g
  protected icons = [
    {
      name: 'BulletIcon',
      class: 'bullet',
    },
    {
      name: 'CheckIcon',
      class: 'check',
    },
  ]

  get isValid(): boolean | null {
    if (this.showChecker) {
      return !!(
        this.numValid &&
        this.charsValid &&
        this.specialChar &&
        this.uppercaseValid
      )
    } else {
      return null
    }
  }

  get numValid(): boolean {
    return this.hasNumber.test(this.password)
  }

  get charsValid() {
    return this.password.length > 7
  }

  get specialChar() {
    return !!this.password.match(this.validSpecialChars)
  }

  get uppercaseValid() {
    return !!this.password.match(this.hasUppercase)
  }

  selectIcon(conditions: boolean[]) {
    let icon: object | null = null

    conditions.forEach((condition, index) => {
      if (condition) {
        icon = this.icons[index]
      }
    })

    return (
      icon ?? {
        name: 'InfoIcon',
        class: 'error',
      }
    )
  }

  get specialCharacterIcon() {
    const conditions = [!this.specialChar && !this.showErrors, this.specialChar]

    return this.selectIcon(conditions)
  }

  get uppercaseValidIcon() {
    const conditions = [
      !this.uppercaseValid && !this.showErrors,
      this.uppercaseValid,
    ]

    return this.selectIcon(conditions)
  }

  get numValidIcon() {
    const conditions = [!this.numValid && !this.showErrors, this.numValid]

    return this.selectIcon(conditions)
  }

  get charsValidIcon() {
    const conditions = [!this.charsValid && !this.showErrors, this.charsValid]

    return this.selectIcon(conditions)
  }

  errorsClass(errors) {
    return errors.length || (!this.charsValid && this.showErrors)
      ? 'field__input--error'
      : ''
  }
}
