







import { Component, Vue } from 'nuxt-property-decorator'
// @ts-ignore
import LoadingWheelBackground from '~/static/images/loading/loading-background.svg?inline'
// @ts-ignore
import LoadingWheelSection from '~/static/images/loading/loading-section.svg?inline'

@Component({
  components: {
    LoadingWheelSection,
    LoadingWheelBackground,
  },
})
export default class LoadingWheel extends Vue {}
