// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes loading{0%{transform:rotate(0deg)}to{transform:rotate(1turn)}}.social-links{width:100%;height:100%;align-items:center}.social-links ul{width:100%;display:grid;height:100%;list-style:none;list-style-image:none;grid:auto/repeat(6,1fr);gap:0;margin:0;padding:0}@media only screen and (min-width:1200px){.social-links ul{column-gap:3.5rem;width:fit-content;margin-left:auto}}.social-links ul li{display:flex;align-items:center;justify-content:center;padding:0}.social-links ul li:before{display:none}@media only screen and (min-width:768px){.social-links ul li{justify-content:flex-end}}.social-links__icon svg{align-items:center;display:block;fill:#f7f6f3}.social-links__icon--instagram svg{height:1.5rem;width:1.5rem}.social-links__icon--tiktok svg{height:1.5rem;width:1.3125rem}.social-links__icon--twitter svg{height:1.21875rem;width:1.5rem}.social-links__icon--youtube svg{height:1.125rem;width:1.5625rem}.social-links__icon--facebook svg{height:1.5rem;width:.75rem}.social-links__icon--linkedin svg{height:1.5rem;width:1.5rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"black": "#000",
	"blackBg": "#121212",
	"white": "#fff",
	"offWhite": "#f7f6f3",
	"grey": "#b7b7b8",
	"darkGrey": "#474747",
	"mediumGrey": "#7d7d7d",
	"lightGrey": "#e0e0e0",
	"darkGrey60": "rgba(66,66,66,.6)",
	"lightGrey60": "hsla(0,0%,87.8%,.6)",
	"red": "#db0000",
	"violet": "#b8a4ea",
	"lime": "#d4fe01",
	"price1": "#e6ff66",
	"price2": "#b699ff",
	"price3": "#66ff91",
	"price4": "#f68383",
	"price5": "#80a4ff",
	"price6": "#ff99c0",
	"price7": "#00b893",
	"price8": "#ff9166",
	"screen375": "375px",
	"screen480": "480px",
	"screen640": "640px",
	"screen768": "768px",
	"screen1024": "1024px",
	"screen1200": "1200px",
	"screen1440": "1440px",
	"screen1560": "1560px"
};
module.exports = ___CSS_LOADER_EXPORT___;
