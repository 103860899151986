export const validateUrl = (value: string): true | string => {
  const pattern = new RegExp(
    '^(http(s):\\/\\/.)[-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&!//=]*)$',
    'i'
  )

  if (value.match(pattern)) {
    return true
  } else {
    return 'Please enter a valid url'
  }
}
