











import { Component, Vue, Prop } from 'nuxt-property-decorator'

@Component
export default class MostSearched extends Vue {
  @Prop({ type: String, default: 'Frequently searched for' }) title!: string
  @Prop({ type: Array, required: true }) items!: {
    title: string
    slug: string
  }[]
}
