









import { Component, Vue, Prop } from 'nuxt-property-decorator'
import { CmsPostSummary } from '@Core/@types/skyway'

@Component
export default class ResultsCard extends Vue {
  @Prop({ required: true }) result!: CmsPostSummary

  get cardType(): string {
    return this.result.type ? this.result.type : 'everything else'
  }

  get slug(): string {
    if (this.result.type === 'event') {
      return `${this.result.slug}`
    }

    // @ts-ignore
    return this.result && this.result.slug
  }
}
