



















import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class StaticNotification extends Vue {
  @Prop({ type: Object, default: () => {} }) notificationLink?: {
    to: string
    label: string
  }

  @Prop({ type: String, default: () => '' }) notificationText?: string
  @Prop({ type: String, default: 'notification--default' })
  notificationType?: string
}
