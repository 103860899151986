




























































import { Component, Vue, Prop } from 'nuxt-property-decorator'
import ResizeObserver from '@UI/components/resize-oberserver/ResizeObserver.vue'
import MenuCloseSvg from '~/static/images/sprites/close.svg?inline'

@Component({
  components: {
    MenuCloseSvg,
    ResizeObserver,
  },
})
export default class Modal extends Vue {
  $refs!: {
    modal: HTMLDialogElement
  }

  protected isOpen: boolean = false

  protected fixedClass: string = ''

  @Prop({ type: Boolean, default: true }) showClose!: boolean
  @Prop({ type: Boolean, default: false }) isSlim!: boolean
  @Prop({ type: Boolean, default: true }) displayTrigger!: boolean
  @Prop({ type: Boolean, default: false }) closeModal!: boolean
  @Prop({ type: Boolean, default: false }) openOnMount!: boolean

  get slimModal(): boolean {
    return this.isSlim
  }

  get showTrigger(): boolean {
    return this.displayTrigger
  }

  get hasHeader(): boolean {
    return Boolean(this.$slots.header)
  }

  open(): void {
    this.isOpen = true
    this.$emit('opened')
    this.$refs.modal.showModal()
  }

  close(): void {
    this.$emit('closed')
    const modal = this.$refs.modal
    if (modal) {
      this.isOpen = false
      try {
        modal.close()
      } catch (err_) {}
    }
  }

  polyfillDialog() {
    this.$dialog.registerDialog(this.$refs.modal)
    this.fixedClass = 'fixed'
    if (!document.body.classList.contains('no-dialog')) {
      document.body.classList.add('no-dialog')
    }
  }

  async mounted() {
    if (typeof window.HTMLDialogElement === 'undefined') {
      this.polyfillDialog()
    }
    if (this.openOnMount) {
      await this.$nextTick()
      this.open()
    }
  }
}
