

















import { Component, Emit, Prop, VModel, Vue } from 'nuxt-property-decorator'
import TextField from '@UI/components/forms/fields/TextField.vue'

@Component({
  components: {
    TextField,
  },
})
export default class SearchBar extends Vue {
  @VModel({ type: String }) searchTerm!: string
  @Prop({ type: Boolean, default: false }) loading!: boolean

  get hint(): string {
    if (this.searchTerm.length === 0) {
      return ''
    } else if (this.searchTerm.length < 4) {
      return `Type ${4 - this.searchTerm.length} more letters to search`
    } else if (this.loading) {
      return 'Searching'
    } else {
      return 'Press enter to search'
    }
  }

  focus() {
    this.$refs?.field && (this.$refs.field as HTMLInputElement).focus()
  }

  @Emit('close')
  close() {
    this.searchTerm = ''
    return this.searchTerm
  }

  search(): void {
    this.$emit('search', this.searchTerm)
  }
}
