













import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component({})
export default class TransitionWrapper extends Vue {
  @Prop({ type: Number, default: 0 }) delay!: number
  @Prop({ type: String, default: 'fade' }) name!: string
  @Prop({ type: Boolean, default: false }) immediate!: boolean

  public hasMounted: boolean = false
  public animate: boolean = false

  intersected() {
    setTimeout(() => {
      this.animate = true
    }, this.delay)
  }

  mounted() {
    setTimeout(() => {
      this.hasMounted = true
    }, this.delay)
  }
}
