





































































import { Component, Prop, Vue, VModel } from 'nuxt-property-decorator'
import { ValidationProvider } from 'vee-validate'
import CheckMarked from '~/static/images/svgs/checkbox-marked.svg?inline'
import CheckEmpty from '~/static/images/svgs/checkbox-blank-outline.svg?inline'

@Component({
  components: {
    ValidationProvider,
    CheckMarked,
    CheckEmpty,
  },
})
export default class CheckboxField extends Vue {
  @Prop({ type: String }) label?: string
  @Prop({ type: String }) sublabel?: string
  @Prop({}) inputValue!: any
  @Prop({ type: Number, required: false }) index?: number
  @Prop({ type: [String, Boolean] }) image?: string | false
  @Prop({ type: String }) description?: string
  @Prop({ type: String }) title?: string
  @Prop({ type: Boolean, default: false }) boxed!: boolean
  @Prop({ type: Boolean }) disabled?: boolean
  @Prop({ type: Boolean, default: false }) required!: boolean

  @VModel() isChecked!: any

  get hasImage(): Boolean {
    if (this.image) {
      return true
    } else {
      return false
    }
  }

  get showCheckedIcon(): boolean {
    if (typeof this.isChecked === 'boolean') {
      return this.isChecked
    } else if (this.isChecked) {
      return this.isChecked.includes(this.inputValue)
    } else {
      return false
    }
  }

  get requiredRule() {
    if (this.required) {
      return { required: { allowFalse: false } }
    } else {
      return ''
    }
  }

  toggleSelection(): void {
    this.$refs.input.click()

    /** alt solution 
    if (typeof this.isChecked === 'boolean') {
      this.isChecked = !this.isChecked
    } else {
      this.isChecked.includes(this.inputValue)
        ? this.isChecked.splice(this.isChecked.indexOf(this.inputValue), 1)
        : this.isChecked.push(this.inputValue)
    } */
  }
}
